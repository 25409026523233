import React from 'react';
import './Avatar.scss';

interface Props {
  firstName: string;
  lastName: string;
}

const Avatar: React.FC<Props> = ({ firstName, lastName }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getUserImg = () => {
    return null;
  };

  const initials = `${firstName[0]}${lastName[0]}`;

  const getColourClass = (initial: string | null) => {
    if (!initial || typeof initial !== 'string') {
      return null;
    }

    const letterAsNumber = parseInt(initial, 36) - 9;
    const classtext = 'avatar--colour';

    switch (true) {
      case letterAsNumber <= 3: // A B C
        return `${classtext}-a`;

      case letterAsNumber <= 6: // D E F
        return `${classtext}-b`;

      case letterAsNumber <= 9: // G H I
        return `${classtext}-c`;

      case letterAsNumber <= 12: // J K L
        return `${classtext}-d`;

      case letterAsNumber <= 15: // M N O
        return `${classtext}-e`;

      case letterAsNumber <= 21: // P Q R S T U
        return `${classtext}-f`;

      case letterAsNumber <= 24: // V W X
        return `${classtext}-g`;

      case letterAsNumber <= 26: // Y Z
        return `${classtext}-h`;

      default:
        return `${classtext}-a`;
    }
  };
  const colourClass = getColourClass(lastName[0]);

  return (
    <div
      className={`avatar avatar__initials avatar--large ${colourClass}`}
      data-testid="avatar"
    >
      <span
        className={`avatar__initials ${colourClass}`}
        data-automation="avatar-initials"
        data-testid="avatar-initials"
      >
        {initials?.toUpperCase()}
      </span>
    </div>
  );
};

export default Avatar;
