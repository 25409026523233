export enum PlatformOS {
  Mac = 'MAC',
  Windows = 'WINDOWS',
  Linux = 'LINUX',
  Android = 'ANDROID',
  IOS = 'IOS',
}

export const getPlatformOS = (): PlatformOS | null => {
  let os = null;
  const { userAgent } = window.navigator;

  const isIOS =
    (/iPad|iPhone|iPod/.test(userAgent) ||
      (/Mac|Mac OS|MacIntel/gi.test(userAgent) &&
        (navigator.maxTouchPoints > 1 || 'ontouchend' in document))) &&
    !(window as any).MSStream;

  if (isIOS) {
    os = PlatformOS.IOS;
  } else if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
    os = PlatformOS.Mac;
  } else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
    os = PlatformOS.Windows;
  } else if (/Android/gi.test(userAgent)) {
    os = PlatformOS.Android;
  } else if (/Linux/gi.test(userAgent)) {
    os = PlatformOS.Linux;
  }

  return os;
};

export const isMac = () => {
  return getPlatformOS() === PlatformOS.Mac;
};

export const isWindows = () => {
  return getPlatformOS() === PlatformOS.Windows;
};
