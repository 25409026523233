import React, { useEffect, useRef } from 'react';
import Avatar from '../Avatar/Avatar';
import './JoinRoom.scss';

export interface Invite {
  dialInNumber: string;
  firstName: string;
  guestLinkUUID: string;
  lastName: string;
  roomId: number;
  threadId: string;
}

interface Props {
  invite: Invite;
}

export const JoinRoom: React.FC<Props> = ({ invite }) => {
  const appProtocolUrl = `collaborate-evolution://${
    window.location.host
  }/guest?invite=${window.btoa(JSON.stringify(invite))}`;

  const opener =
    document.querySelector<HTMLIFrameElement>('iframe#app-opener') ??
    (() => {
      const iframe = document.createElement('iframe');
      iframe.id = 'app-opener';
      iframe.style.display = 'none';
      iframe.src = appProtocolUrl;
      iframe.setAttribute('data-testid', 'app-opener');
      return iframe;
    })();

  const injectRef = useRef<HTMLDivElement>(null);
  const openerRef = useRef<HTMLIFrameElement>(opener);

  useEffect(() => {
    if (injectRef.current && !injectRef.current.contains(openerRef.current)) {
      injectRef.current.appendChild(opener);
    }
  }, [injectRef, openerRef]);

  const owner = `${invite.firstName} ${invite.lastName}`;
  const phoneNumber = invite.dialInNumber;
  const roomId = invite.roomId;

  return (
    <>
      <div className="join-room-container" ref={injectRef}>
        <div className="join-room-user-row">
          <Avatar firstName={invite.firstName} lastName={invite.lastName} />
          <div className="label-cell" data-testid="join-room-message">
            Join{' '}
            <strong style={{ color: '#1B1F3B' }}>&nbsp;{owner}'s&nbsp;</strong>{' '}
            room via the app
          </div>
          <a
            className="join-room-cell"
            href={appProtocolUrl}
            data-testid="join-room-link"
          >
            Join room
          </a>
        </div>
        <div className="join-room-alternate-row" data-testid="join-room-dialin">
          <p className="alternate-cell">
            Or join by dialing{' '}
            <strong className="lato-bold">&nbsp;{`${phoneNumber}`}</strong>
          </p>
          <p className="alternate-cell">
            <strong style={{ color: '#1B1F3B' }}>Room ID: {roomId}</strong>
          </p>
        </div>
      </div>
    </>
  );
};
