import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import '../App.scss';
import illistationRoomsSvg from '../assets/images/illistration-rooms.svg';

import { Logo } from './Logo';
import { Invite, JoinRoom } from './JoinRoom/JoinRoom';
import { DownloadContainer } from './DownloadContainer/DownloadContainer';

export const Home: React.FC<any> = () => {
  const { search } = useLocation();
  const [inviteData, setInviteData] = useState<Invite | null>(null);
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const inviteParam = searchParams.get('invite');

  useEffect(() => {
    if (inviteParam) {
      try {
        setInviteData(JSON.parse(window.atob(inviteParam || '')));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('invalid invite');
      }
    }
  }, [inviteParam]);

  return (
    <div className="layout" data-test="main-layout">
      <section className="main-container">
        <Logo />
        <header className="main-container__header">
          {inviteData && (
            <p className="main-container__intro" data-test="login-subtitle">
              {inviteData.firstName} has invited you to join their room
            </p>
          )}
        </header>
        <div className="main-container__body">
          <DownloadContainer />
          {inviteData && <JoinRoom invite={inviteData} />}
        </div>
        <figure className="main__figure">
          <img
            src={illistationRoomsSvg}
            alt="collage of people talking to each other"
          />
        </figure>
      </section>
    </div>
  );
};
