import { isMac } from './helpers/platform';

declare global {
  interface Window {
    __env__?: Record<string, string>;
  }
}

const getEnv = (key: string) => window?.__env__?.[key];

export default {
  GUEST_CLIENT_DOWNLOAD_URL: isMac()
    ? getEnv('GUEST_CLIENT_DOWNLOAD_URL_MAC')
    : getEnv('GUEST_CLIENT_DOWNLOAD_URL_WINDOWS'),
  GUEST_CLIENT_DOWNLOAD_NAME: isMac()
    ? getEnv('GUEST_CLIENT_DOWNLOAD_NAME_MAC')
    : getEnv('GUEST_CLIENT_DOWNLOAD_NAME_WINDOWS'),
};
