import './DownloadContainer.scss';
import '../../styles/_03-elements/buttons.scss';

import React from 'react';

import config from '../../config';
import computerIcon from './computer-icon.svg';
import { isMac, isWindows } from '../../helpers/platform';

export const DownloadContainer: React.FC = () => {
  const isDisabled = !isMac() && !isWindows();

  return (
    <div className="download-container">
      <div className="icon-container">
        <img
          className="icon-container__img"
          src={computerIcon}
          alt="computer icon"
        />
      </div>
      <div className="text-container">
        <p className="text-container__p--bold">Collaborate</p>
        <p className="text-container__p--subtitle">
          Calls and video conferencing
        </p>
      </div>
      {isDisabled && (
        <button className="btn btn--primary" disabled>
          Download
        </button>
      )}
      {!isDisabled && (
        <a
          className="btn btn--primary"
          href={config.GUEST_CLIENT_DOWNLOAD_URL}
          download={config.GUEST_CLIENT_DOWNLOAD_NAME}
          target="_blank"
          rel="nofollow"
        >
          Download
        </a>
      )}
    </div>
  );
};
